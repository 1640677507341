<template>
  <div class="single-bridge-common">
    <div class="mb-2">
      <el-button class="btn-primary" @click="goto()">新增</el-button>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          v-loading="pageLoading"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-edit mr-6" @click="goto(row)">编辑</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="700px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="24">
              <el-form-item label="传感器" prop="sensor">
                <el-cascader
                    :options="sensorList"
                    :props="props"
                    collapse-tags
                    v-model="form.model.sensor"
                    clearable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-for="(item,i) in form.model.nameList" :key="i">
              <el-form-item :label="'名称' + (i + 1)" :prop="'nameList.' + i + '.name'" :rules="form.rules.name">
                <div class="form-flex">
                  <el-input v-model="item.name"/>
                  <div class="form-item-btn" v-if="isAdd">
                    <el-button size="mini" type="danger" icon="el-icon-minus" circle @click="removeForm(i)"></el-button>
                    <el-button v-if="i === form.model.nameList.length - 1" size="mini" type="primary" icon="el-icon-plus" circle @click="addForm"></el-button>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import MHandle from "@/mixins/MHandle.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "/sensorPosition/page",
    pageDelPath: "/sensorPosition/delete",
    pageDelsPath: "",
    pageParams: {},
  });
  let mHandle = MHandle({
    handleGetPath: "",
    handleInsertPath: "",
    handleUpdatePath: "",
    handleDownLoadPath: "",
    handleData: {},
  });
  export default {
    mixins: [mPage, mHandle],
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        form:{
          model:{
            sensor:'',
            nameList:[{name: ''}]
          },
          rules:{
            sensor:[
              { required: true, message: '请选择传感器', trigger: 'blur' }
            ],
            name:[
              { required: true, message: '请填写', trigger: 'blur' }
            ]
          }
        },
        sensorList:[],
        props:{
          value:'value',
          label:'name',
          children:'children'
        },
        isAdd:true
      };

    },
    created() {
      this.getSensor()
    },
    methods: {
      getSensor(){
        this.$http.get("/sensorPosition/getGroupSensorDetailType").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
            }
          }
        })
      },
      addForm(){
        this.form.model.nameList.push({
          name: ''
        })
      },
      removeForm(index){
        this.form.model.nameList.splice(index,1)
      },
      goto(data) {
        this.form.model = {
          nameList: [{
            name: ''
          }]
        }
        if (data) {
          this.title = "编辑配置信息";
          this.isAdd = false
          this.getDetail(data.id)
        } else {
          this.title = "新增配置信息";
          this.isAdd = true
          this.dialogVisible = true;
        }
      },
      getDetail(id){
        this.$http.get('/sensorPosition/getById',{params:{id:id}}).then(res => {
          if (res.success){
            this.form.model = {
              id:res.data.id,
              sensor:[res.data.monitorDictId,res.data.sensorDetailType],
              nameList: [{name:res.data.name}]
            }
            this.dialogVisible = true;
          }
        })
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              sensorDetailType:this.form.model.sensor[1],
              monitorDictId:this.form.model.sensor[0],
              // nameList:[]
            }
            let api = ''
            if (this.isAdd === true){
              api = "/sensorPosition/add"
              params.nameList = []
              for (let item of this.form.model.nameList){
                if (item.name){
                  params.nameList.push(item.name)
                }
              }
            } else {
              api = "/sensorPosition/update"
              params.id = this.form.model.id
              params.name = this.form.model.nameList[0].name
            }
            this.$http.post(api,params).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet()
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
</style>