import store from "@/store/index.js";
let dic = store.state.dic;

export default function Configs(that) {
  const tableColumns = [
    {
      label: "名称",
      prop: "name",
      align: "center",
      minWidth: 140,
      show: true
    },
    // {
    //   label: "传感器类型",
    //   prop: "card",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "测点编号",
    //   prop: "card",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "报警规则",
    //   prop: "card",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "报警级别",
    //   prop: "card",
    //   align: "center",
    //   show: true
    // },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
